// @ts-nocheck

import React from 'react';
import * as State from '../../reducers/state';
import { connect } from 'react-redux';
import { Loading } from '../common/loading/loading';
import { Dispatch } from 'redux';
import { Actions } from '../../actions/language';
import { CmsService } from '../../services/cms-service';
import { ResponseError } from '../../services/api-service';
import { DevErrorView } from '../common/dev-error-view';
import { RedirectToSource } from '../../misc/common';
import ErrorPage from '../common/error-page/error-page';

/** State props for the WithCms HOC */
export interface WithCmsStateProps {
    isLoaded: boolean;
    lastError?: ResponseError;
    isRefreshing: boolean;
    languageId: number;
    branding: string | undefined;
    isPreview: boolean;
}

/** Dispatch props for the WithCms HOC */
export interface WithCmsDispatchProps {
    onRequestData: (language: number, preview: boolean, branding: string | undefined) => void;
}

/**
 * Higher order component to ensure that cms data has been fetched from the server and is present in the Redux store before
 * rending the inner component provided.
 * @param Component The actual component to render once data is present
 * 
 * This HOC is required in the case of the page being refreshed in the browser, the session will exist but the Redux store would be empty.
 * To get around this render a loading spinner whilst doing the initial data fetch, then once the store has been updated render the real
 * page.
 */
export default function withCms<P, S>(Component: React.ComponentClass<P>): React.ComponentClass<P> {

    const mapStateToProps = (state: State.All, ownProps: P): WithCmsStateProps => ({
        isLoaded: state.language.isLoaded,
        lastError: state.language.lastError,
        isRefreshing: state.language.fetchingText,
        languageId: state.language.languageId,
        branding: state.branding.hasDashboardManifest ? state.branding.name : undefined,
        isPreview: state.candidate.isPreviewMode
    });

    const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
        onRequestData: (language: number, preview: boolean, branding: string | undefined) => {
            CmsService.GetSiteText(language, preview, branding).then(r => {
                dispatch(Actions.changeLanguageSuccess(language, r, false));
            })
            .catch((error: ResponseError) => dispatch(Actions.changeLanguageError(error)));
        }
    });

    class C extends React.Component<P & WithCmsStateProps & WithCmsDispatchProps, S> {
        inDevMode = process.env.NODE_ENV === `development`;

        render() {
            // If the cms has been loaded and is not refreshing                     
            if (this.props.isLoaded && !this.props.isRefreshing) {
                return <Component {...this.props} />;
            } else if (this.props.isRefreshing) {
                return <Loading />;
            }

            if (this.props.lastError !== undefined) {
                if (this.inDevMode) {
                    return (
                        <DevErrorView
                            message="An error occurred during cms fetch"
                            errorResponse={this.props.lastError}
                        />
                    );
                }

                if(this.props.lastError.status >= 500 && this.props.lastError.status < 600){
                    return (
                        <ErrorPage/>
                    );
                }

                // Kick back out to the KF signin app or session timeout page if user platform is IC2
                RedirectToSource();
            }

            this.props.onRequestData(this.props.languageId, this.props.isPreview, this.props.branding);
            return <Loading />;
        }
    }

    return connect(
        mapStateToProps, 
        mapDispatchToProps)(C);
}