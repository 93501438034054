import React from "react";
import { EmptyObject } from "../../../misc/common";

export default class ErrorPage extends React.Component<EmptyObject> {
    logoUrl: string = "/images/logo-kornferry.svg";
    currentYear: number = new Date().getFullYear();
    footerCopyrightText: string = `© Korn Ferry ${this.currentYear} All Rights Reserved`

    private handleReload = () => {
        window.location.reload();
    };

    footerLinks = [
        {
            href: "https://www.kornferry.com/content/dam/kornferry/docs/Terms-of-Use-011812v2.pdf",
            label: "Terms of Use",
            ariaLabel: "Terms of use",
            target: "_blank",
        },
        {
            href: "https://cdn.kornferry.com/privacy/en-us/privacy.pdf",
            label: "Privacy Policy",
            ariaLabel: "Privacy Policy",
        },
    ];

    render() {
        return (
            <div className="error-page">
                <header>
                    <div className="navigation-brand-image">
                        <img className="img-margin" src={this.logoUrl} alt="Error" />
                    </div>
                </header>
                <div className="error-page-wrapper">
                    <div className="icon-wrapper">
                        <span className="error-icon" role="img" />
                    </div>
                    <h1 className="title">It’s not you. It’s us. We promise.</h1>
                    <p className="description">
                        We’re working hard to fix this. Please check back later.
                    </p>
                    <button className="btn-action" onClick={this.handleReload}>
                        Reload Page
                    </button>
                </div>
                <footer className="footer">
                    <div className="footer-bar">
                        <div className="copyright">
                            <img src="/images/logo-kornferryFooter.svg" alt="Korn Ferry" />
                            <p>{this.footerCopyrightText}</p>
                        </div>

                        <div className="links-right">
                            <nav aria-label="Footer Links">
                                <ul className="list-inline">
                                    {this.footerLinks.map(
                                        ({ href, label, ariaLabel, target }, index) => (
                                            <li key={index} className="list-inline-item">
                                                <a href={href} target={target} aria-label={ariaLabel}>
                                                    {label}
                                                </a>
                                            </li>
                                        )
                                    )}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}
